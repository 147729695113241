<template>
    <div>
        <div>
            <b-card>
                <div id="container-map" v-if="status_mapa">
                    <l-map :center="center" :zoom="10" ref="mymap" :options="{zoomControl: false}">
                        <l-control-layers :collapsed="true" :position="layersPosition" :sort-layers="true"/>
                        <l-tile-layer :key="tileProvider.key" :name="tileProvider.name" :url="tileProvider.url" :visible="tileProvider.visible" layer-type="base" v-for="tileProvider in tileProviders"/>
                        <l-control-zoom position="topleft" :options="{ zoomInTitle: $t('Labels.ZoomIn'), zoomOutTitle: $t('Labels.ZoomOut') }"/>
                        <l-control position="topleft" v-if="showButtons === true">
                          <b-button v-if="fitBounds == false"  @click="fitBounds = true" class="btn-icon btn-map rounded-circle" variant="primary" :title="$t('Labels.Follow')" v-b-tooltip.hover="{ variant: 'primary', placement: 'right' }" >
                            <feather-icon icon="EyeIcon" :width="18" :height="18"/>
                          </b-button>
                          <b-button v-else @click="fitBounds = false" class="btn-icon btn-map rounded-circle" variant="primary" :title="$t('Labels.StopFollow')" v-b-tooltip.hover="{ variant: 'primary', placement: 'right' }" >
                            <feather-icon icon="EyeOffIcon" :width="18" :height="18"/>
                          </b-button>
                          <b-button
                            variant="primary"
                            class="btn-icon rounded-circle btn-map"
                            :to="`/groups/members/${trackerid}/${uuid}`"
                            :title="$t('Labels.Members')" v-b-tooltip.hover="{ variant: 'primary', placement: 'right' }"
                          >
                            <feather-icon icon="UsersIcon" :width="18" :height="18"/>
                          </b-button>
                          <b-button
                            v-if="response.user_group[0].is_administrator"
                            variant="primary"
                            class="btn-icon rounded-circle btn-map"
                            :to="`/groups/edit/${trackerid}/${uuid}`"
                            :title="$t('Labels.Edit')" v-b-tooltip.hover="{ variant: 'primary', placement: 'right' }"
                          >
                            <feather-icon icon="EditIcon" :width="18" :height="18"/>
                          </b-button>
                          <b-button
                            v-if="response.user_group[0].is_administrator"
                            variant="danger"
                            class="btn-icon rounded-circle btn-map"
                            @click="confirmDelete(uuid)"
                            :title="$t('Labels.Delete')" v-b-tooltip.hover="{ variant: 'danger', placement: 'right' }"
                          >
                            <feather-icon icon="TrashIcon" :width="18" :height="18"/>
                          </b-button>
                          <b-button
                            variant="danger"
                            class="btn-icon rounded-circle btn-map"
                            @click="confirmExit(uuid)"
                            :title="$t('Labels.Exit')" v-b-tooltip.hover="{ variant: 'danger', placement: 'right' }"
                          >
                            <feather-icon icon="LogOutIcon" :width="18" :height="18"/>
                          </b-button>
                        </l-control>
                        <l-control position="verticalcenterright" class="leaflet-topcenter">
                          <div v-if="this.onAlert" class="container-tags my-1">
                            <h5>{{$t('Labels.TrackerOnAlert')}}</h5>
                            <badgeTracker v-for="item in alertTrackers" :key="item.id"
                              :label="item.name"
                              :status="item.status"
                              @click="goToMarker(item.position)"
                            />
                          </div>
                        </l-control>
                        <v-marker-cluster>
                            <l-marker :draggable="item.draggable" :key="item.id" :lat-lng.sync="item.position" :visible="item.visible" v-for="item in markers">
                                <l-icon :icon-size="[40,40]" >
                                  <avatarMarker
                                    :label="item.name.substr(0,2)"
                                    :status="item.status"
                                  />
                                </l-icon>
                                <l-popup>
                                    <div class="popup">
                                      <gridPrincipalItem
                                        img="pointtracker"
                                        :name="item.name"
                                        :description="$t('Labels.Alias')"
                                      />
                                      <hr>
                                      <valueItem
                                        :value="item.lat"
                                        :description="$t('Labels.Latitude')"
                                        img="georeference"
                                      />
                                      <valueItem
                                        :value="item.long"
                                        :description="$t('Labels.Longitude')"
                                        img="georeference"
                                      />
                                      <valueItem
                                        :value="item.battery"
                                        :description="$t('Labels.Battery')"
                                        img="battery"
                                        unit="%"
                                      />
                                      <hr>
                                      <alertTracker :status="item.status" />
                                      <valueItem
                                        :value="item.updated_at.split(' ')[0]"
                                        :description="item.updated_at.split(' ')[1]"
                                        img="fecha"
                                      />
                                      <div class="text-center">
                                        <b-button @click="deviceInformation(item.id)" variant="primary" size="sm">
                                          {{$t('Labels.SeeMore')}}
                                        </b-button>
                                      </div>

                                </div>
                                </l-popup>
                            </l-marker>
                        </v-marker-cluster>
                    </l-map>
                </div>
            </b-card>
        </div>
    </div>
</template>
<script>
import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LPopup,
  LControlLayers,
  LControl,
  LControlZoom
} from 'vue2-leaflet'
import L from 'leaflet'
import Vue2LeafletMarkercluster from 'vue2-leaflet-markercluster/Vue2LeafletMarkercluster'
import gridPrincipalItem from '@core/spore-components/gridPrincipalItem/gridPrincipalItem.vue'
import valueItem from '@core/spore-components/valueItem/valueItem.vue'
import alertTracker from '@core/spore-components/alertTracker/alertTracker.vue'
import avatarMarker from '@core/spore-components/avatarMarker/avatarMarker.vue'
import request from '@/libs/request/index'
import 'leaflet/dist/leaflet.css'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'
import 'leaflet-fullscreen/dist/Leaflet.fullscreen'
import { batteryCalculate, empty } from '@/libs/tools/helpers'
import {
  BButton,
  BCard
} from 'bootstrap-vue'
import badgeTracker from '@core/spore-components/badgeTracker/badgeTracker.vue'
import { app } from '@/main'

const tileProviders = [
  {
    key: 'gray',
    name: app.$t('Labels.Gray'),
    visible: true,
    url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'
  },
  {
    key: 'street',
    name: app.$t('Labels.Street'),
    visible: false,
    url: 'https://{s}.tile.openstreetmap.fr/osmfr//{z}/{x}/{y}.png'
  },
  {
    key: 'satellite',
    name: app.$t('Labels.Satellite'),
    visible: false,
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png'
  }
]

const DATA = {
  code: 200,
  data: [],
  msg: 'Ok'
}

export default {
  name: 'mapa',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LIcon,
    LControlLayers,
    LControlZoom,
    'v-marker-cluster': Vue2LeafletMarkercluster,
    gridPrincipalItem,
    valueItem,
    alertTracker,
    BButton,
    BCard,
    avatarMarker,
    LControl,
    badgeTracker
  },
  data () {
    return {
      response: [],
      tileProviders,
      layersPosition: 'topleft',
      center: [20.662396323989693, -103.37640969334421],
      staticAnchor: [12, 27],
      markers: [],
      status_mapa: false,
      DATA,
      batteryCalculate,
      uuid: this.$route.params.uuid,
      trackerid: this.$route.params.trackerid,
      showButtons: false,
      fitBounds: true,
      alertTrackers: [],
      onAlert: false
    }
  },
  methods: {
    async getMapa () {
      const params = {
        url: `tracker_groups/${this.uuid}`,
        method: 'GET'
      }
      await request(params).then(data => {
        this.response = data.data.data[0]
        data = data.data.data[0].user_group
        const positions = []
        this.onAlert = false
        this.alertTrackers = []
        this.markers = []
        for (let i = 0; i < data.length; i++) {
          const lat = data[i].tracker.lat
          const lng = data[i].tracker.long
          positions.push([lat, lng])
          this.markers.push({
            position: { lat: lat, lng: lng },
            name: data[i].tracker.name,
            status: data[i].tracker.tipo_alerta,
            updated_at: data[i].tracker.created_at,
            lat: data[i].tracker.lat,
            long: data[i].tracker.long,
            battery: batteryCalculate(data[i].tracker.battery),
            id: data[i].tracker_uuid,
            draggable: false,
            visible: true
          })
          if (data[i].tracker.tipo_alerta !== 'S') {
            this.onAlert = true
            this.alertTrackers.push({
              id: data[i].tracker_uuid,
              name: data[i].tracker.name,
              status: data[i].tracker.tipo_alerta,
              position: [lat, lng]
            })
          }
        }
        this.status_mapa = true

        const app = this
        setTimeout(function () {
          const maps = app.$refs.mymap.mapObject
          maps.addControl(new L.Control.Fullscreen({
            position: 'topleft',
            title: {
              false: app.$t('Labels.Fullscreen'),
              true: app.$t('Labels.ExitFullscreen')
            }
          }))
          maps.fitBounds(positions)
          app.showButtons = true
        }, 1000)
      }).catch(() => {
        this.markers = []
      })
    },

    deviceInformation (uuid) {
      this.$router.push(`/tracker/detail/${this.trackerid}`)
    },

    confirmDelete (uuid) {
      this.$swal({
        title: this.$t('Messages.ConfirmDeleteGroup'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Messages.Yes'),
        cancelButtonText: this.$t('Messages.Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          const params = {
            url: `tracker_groups/${uuid}`,
            method: 'DELETE'
          }
          await request(params).then(response => {
            this.getResponse()
          })
        }
      })
    },
    confirmExit (uuid) {
      this.$swal({
        title: this.$t('Messages.ConfirmExit'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Messages.Yes'),
        cancelButtonText: this.$t('Messages.Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          const params = {
            url: `tracker_groups/exit/${this.uuid}/${uuid}`,
            method: 'DELETE'
          }
          await request(params).then(response => {
            this.getResponse()
          })
        }
      })
    },
    goToMarker (position) {
      this.fitBounds = false
      const map = this.$refs.mymap.mapObject
      map.setView(position, 18)
    },
    setFitBounds () {
      const positions = []
      this.markers.forEach(item => {
        positions.push([item.position.lat, item.position.lng])
      })
      const maps = this.$refs.mymap.mapObject
      if (maps !== null) {
        maps.fitBounds(positions)
      }
    }
  },
  sockets: {
    trackers (payload) {
      if (!empty(payload.lat) && payload.lat !== '0' && !empty(payload.long) && payload.long !== '0') {
        this.markers = this.markers.filter(marker => marker.id !== payload.uuid)
        this.markers.push({
          position: { lat: payload.lat, lng: payload.long },
          name: payload.name,
          status: payload.tipo_alerta,
          updated_at: payload.updated_at,
          deveui: payload.deveui,
          lat: payload.lat,
          long: payload.long,
          battery: batteryCalculate(payload.battery),
          id: payload.uuid,
          draggable: false,
          visible: true
        })
        if (this.fitBounds) {
          this.setFitBounds()
        }
        if (payload.tipo_alerta !== 'S') {
          this.alertTrackers = this.alertTrackers.filter(marker => marker.id !== payload.uuid)
          this.onAlert = true
          this.alertTrackers.push({
            id: payload.uuid,
            name: payload.name,
            status: payload.tipo_alerta,
            position: [payload.lat, payload.long]
          })
        }
      }
    }
  },
  created () {
    this.getMapa()
  }
}
</script>
<style lang="scss" scoped>
  @import "~leaflet.markercluster/dist/MarkerCluster.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
  @import "@/assets/scss/pages/MapGroups.scss";
</style>
