<template>
  <b-button :variant="variant" class="button-badge onAlert" size="sm" @click="$emit('click')">
    <iconMedic v-if="status === 'M'"
      class="alerIcon d-inline align-middle"
      width="10"
      height="10"
    />
    <iconPolice v-else-if="status === 'P'"
      class="alerIcon d-inline align-middle"
      width="10"
      height="10"
    />
    <iconFire v-else-if="status === 'B'"
      class="alerIcon d-inline align-middle"
      width="10"
      height="10"
      iconColor="#bb0d0d"
    />
    <iconContact v-else-if="status === 'C'"
      class="alerIcon d-inline align-middle"
      width="10"
      height="10"
    />
    <span class="align-middle label-badge">{{label}}</span>
  </b-button>
</template>
<script>
import {
  BButton
} from 'bootstrap-vue'
import iconFire from '@core/spore-components/icons/iconFire'
import iconContact from '@core/spore-components/icons/iconContact'
import iconPolice from '@core/spore-components/icons/iconPolice'
import iconMedic from '@core/spore-components/icons/iconMedic'
export default {
  components: {
    BButton,
    iconFire,
    iconContact,
    iconPolice,
    iconMedic
  },
  props: {
    label: String,
    status: {
      type: String,
      required: true
    }
  },
  computed: {
    variant () {
      return this.status === 'S' ? 'outline-success' : 'outline-danger'
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/spore-components/badgeTracker.scss";
</style>
